import { Route, Routes } from "react-router-dom";
import Registration from '../registration/registration';
import Paysuccess from '../registration/paysuccess';
import PayError from '../registration/paysuccess';
import Layout, { LocaleLayout, NotFoundLayout } from "../specialRoutes/layouts";

const Root = () => {



    return (
    <Routes>
        <Route path="/" element={<Layout />}>
            <Route path=':lang' element={<LocaleLayout />}>
                <Route path="registration" element={<Registration />} />
                <Route path="pay_success" element={<Paysuccess />} />
                <Route path="pay_error" element={<PayError />} />
                <Route path="*" element={<NotFoundLayout />} />
            </Route>
        </Route>
    </Routes>
    );
};

export default Root;