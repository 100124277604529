import { Button, Card, Col, DatePicker, Input, Modal, Row, Select, Space } from 'antd';
import React, { Fragment } from 'react';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { Country, Customer, Participant, Product, ProductDetail } from '../../utils/types';
import 'moment/locale/fr-ch';
import locale from 'antd/es/date-picker/locale/fr_FR';
import { Moment } from 'moment';
import moment from 'moment';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { sizeJacket } from './registration';

interface IProps {
    countries: Country[] | undefined;
    participant: Participant;
    participantsTabDisabled: boolean;
    participantToEditId?: number;
    productsTabDisabled: boolean;
    productSelected?: Product;
    detailSelected?: ProductDetail;
    customer?: Customer;
    id: number;

    goToTab: (tabId: string) => void;
    addParticipant: (participant: Participant) => void;
    editParticipants: (id: number, participant: Participant) => void;
    saveEditParticipant: (id: number, participant: Participant) => void;
    deleteParticipant: (id: number) => void;
    cancelParticipant: () => void;
};

interface State {
    participant: Participant;
    isEditing: boolean;
    inputsAreValidated: boolean;
    mustAVS: boolean;
    selectedCountry?: Country;
    avsError: boolean;
}

type Props = IProps & WrappedComponentProps;

class ParticipantItem extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            participant: this.props.participant,
            isEditing: true,
            inputsAreValidated: false,
            mustAVS: false,
            avsError: false,
        };
    }

    componentDidMount() {
        if (this.props.participant.firstName) {
            this.setState({ isEditing: false });
        }
    }

    componentDidUpdate(prevProps: Props) {
        const { participant, inputsAreValidated, mustAVS, selectedCountry, isEditing, avsError } = this.state;
        const { productSelected, detailSelected, customer } = this.props;
        if (prevProps !== this.props && this.props.participantToEditId !== -1) {
            // #TODO Temporaire
            //let mustAVS: boolean = (this.props.participant && this.props.participant.country === 220) ? true : false;
            // let mustAVS: boolean = false
            const selectedCountry = this.props.countries?.find(c => c.id === this.props.participant.country);
            this.setState({ participant: { ...this.props.participant }, mustAVS: false, selectedCountry });
        }

        if (mustAVS && (participant.avs === undefined || participant.avs < 1000000000000)) {
            !avsError && this.setState({ avsError: true });
        } else {
            avsError && this.setState({ avsError: false });
        }
        if (!isEditing && productSelected?.type === 'subscription' && this.props.participant.firstName) {
            let price = -1;
            if (productSelected?.type === 'subscription') {
                if (customer?.domiciledAtCMAndL !== undefined && customer?.domiciledAtCMAndL) {
                    if (participant.birthday && participant.birthday.isSameOrBefore(moment("30/05/2003", 'DD/MM/YYYY'), "days")) {
                        price = detailSelected ? detailSelected.price : 0;
                    } else if (participant.birthday && participant.birthday.isBetween(moment("31/05/2003", 'DD/MM/YYYY'), moment("30/05/2004", 'DD/MM/YYYY'), "days", "[]")) {
                        price = detailSelected && detailSelected.priceTeenager ? detailSelected.priceTeenager : 0;
                    } else {
                        price = 0;
                    }
                } else if (customer?.domiciledAtI !== undefined && customer?.domiciledAtI) {
                    if (participant.birthday && participant.birthday.isSameOrBefore(moment("30/05/2003", 'DD/MM/YYYY'), "days")) {
                        price = detailSelected ? detailSelected.price : 0;
                    } else if (participant.birthday && participant.birthday.isBetween(moment("31/05/2003", 'DD/MM/YYYY'), moment("30/05/2004", 'DD/MM/YYYY'), "days", "[]")) {
                        price = detailSelected && detailSelected.priceTeenager ? detailSelected.priceTeenager : 0;
                    } else {
                        price = 0;
                    }
                } else {
                    if (participant.birthday && participant.birthday.isSameOrBefore(moment("30/05/2003", 'DD/MM/YYYY'), "days")) {
                        price = detailSelected ? detailSelected.price : 0;
                    } else if (participant.birthday && participant.birthday.isBetween(moment("31/05/2003", 'DD/MM/YYYY'), moment("30/05/2008", 'DD/MM/YYYY'), "days", "[]")) {
                        price = detailSelected && detailSelected.priceTeenager ? detailSelected.priceTeenager : 0;
                    } else if (participant.birthday && participant.birthday.isBetween(moment("31/05/2008", 'DD/MM/YYYY'), moment("30/05/2015", 'DD/MM/YYYY'), "days", "[]")) {
                        price = detailSelected && detailSelected.priceJunior ? detailSelected.priceJunior : 0;
                    } else if (participant.birthday && participant.birthday.isSameOrAfter(moment("31/05/2015", 'DD/MM/YYYY'), "days")) {
                        price = 0;
                    } else {
                        price = 0;
                    }
                }
                if (price <= 0 && prevProps === this.props) {
                    this.props.deleteParticipant(this.props.id);
                    Modal.warning({
                        style: { textAlign: 'justify' },
                        width: "700px",
                        title: 'Pass PARTENAIRE Gratuit',
                        content: (
                            <div>
                                <p>{participant.firstName + " " + participant.lastName} est admissible au Pass PARTENAIRE Gratuit, il a donc été retiré de la liste de participants.</p>
                                <p>Les abonnements gratuits ne sont pas commandables depuis le shop. Pour l'obtention d'un abonnement gratuit veuillez contacter directement la CMA.</p>
                            </div>
                        ),
                    });
                }
            }
        }
        // Not add pass if it is free
        if (isEditing && participant.firstName && participant.lastName && participant.gender != null && participant.birthday && selectedCountry && ((mustAVS && participant.avs && participant.avs > 1000000000000) || !mustAVS) && (productSelected?.id !== -1 || (productSelected.id === -1 && participant.sizeJacket))) {
            let price = -1;
            if (productSelected?.type === 'subscription') {
                if (customer?.domiciledAtCMAndL !== undefined && customer?.domiciledAtCMAndL) {
                    if (participant.birthday && participant.birthday.isSameOrBefore(moment("30/05/2003", 'DD/MM/YYYY'), "days")) {
                        price = detailSelected ? detailSelected.price : 0;
                    } else if (participant.birthday && participant.birthday.isBetween(moment("31/05/2003", 'DD/MM/YYYY'), moment("30/05/2004", 'DD/MM/YYYY'), "days", "[]")) {
                        price = detailSelected && detailSelected.priceTeenager ? detailSelected.priceTeenager : 0;
                    } else {
                        price = 0;
                    }
                } else if (customer?.domiciledAtI !== undefined && customer?.domiciledAtI) {
                    if (participant.birthday && participant.birthday.isSameOrBefore(moment("30/05/2003", 'DD/MM/YYYY'), "days")) {
                        price = detailSelected ? detailSelected.price : 0;
                    } else if (participant.birthday && participant.birthday.isBetween(moment("31/05/2003", 'DD/MM/YYYY'), moment("30/05/2004", 'DD/MM/YYYY'), "days", "[]")) {
                        price = detailSelected && detailSelected.priceTeenager ? detailSelected.priceTeenager : 0;
                    } else {
                        price = 0;
                    }
                } else {
                    if (participant.birthday && participant.birthday.isSameOrBefore(moment("30/05/2003", 'DD/MM/YYYY'), "days")) {
                        price = detailSelected ? detailSelected.price : 0;
                    } else if (participant.birthday && participant.birthday.isBetween(moment("31/05/2003", 'DD/MM/YYYY'), moment("30/05/2008", 'DD/MM/YYYY'), "days", "[]")) {
                        price = detailSelected && detailSelected.priceTeenager ? detailSelected.priceTeenager : 0;
                    } else if (participant.birthday && participant.birthday.isBetween(moment("31/05/2008", 'DD/MM/YYYY'), moment("30/05/2015", 'DD/MM/YYYY'), "days", "[]")) {
                        price = detailSelected && detailSelected.priceJunior ? detailSelected.priceJunior : 0;
                    } else if (participant.birthday && participant.birthday.isSameOrAfter(moment("31/05/2015", 'DD/MM/YYYY'), "days")) {
                        price = 0;
                    } else {
                        price = 0;
                    }
                }
                if (price > 0) {
                    !inputsAreValidated && this.setState({ inputsAreValidated: true });
                } else {
                    Modal.warning({
                        style: { textAlign: 'justify' },
                        width: "700px",
                        title: 'Pass PARTENAIRE Gratuit',
                        content: (
                            <div>
                                <p>{participant.firstName + " " + participant.lastName} est admissible au Pass PARTENAIRE Gratuit.</p>
                                <p>Les abonnements gratuits ne sont pas commandables depuis le shop. Pour l'obtention d'un abonnement gratuit veuillez contacter directement la CMA.</p>
                            </div>
                        )
                    });
                }
            } else {
                !inputsAreValidated && this.setState({ inputsAreValidated: true });
            }
        } else {
            inputsAreValidated && this.setState({ inputsAreValidated: false });
        }
    }

    onInputChange = (inputName: string, value: (string | number | undefined)) => {
        let { participant } = this.state;
        const key: (keyof Participant) = inputName as keyof Participant;

        if (!participant) {
            participant = {};
        }

        let parsedValue = value as any;
        if (key === "avs") {
            const re = /^[0-9\b]+$/;
            if (parsedValue !== '' && !re.test(parsedValue)) {
                return;
            }
        }

        participant[key] = parsedValue;

        this.setState({ participant });
    };

    onBirthdayChange = (date: Moment | null) => {
        let { participant } = this.state;
        date !== null && (participant.birthday = date);
        this.setState({ participant });
    };

    onGenderChange = (gender: number) => {
        let { participant } = this.state;
        participant.gender = gender;
        this.setState({ participant });
    };

    addParticipant = () => {
        const { participant } = this.state;
        const { productSelected } = this.props;
        console.log()
        if (participant.firstName && participant.lastName && participant.gender != null && participant.birthday && (productSelected?.id !== -1 || (productSelected.id === -1 && participant.sizeJacket))) {
            if (this.props.participantToEditId !== undefined) { this.props.saveEditParticipant(this.props.participantToEditId, participant); }
            else { this.props.addParticipant(participant); }
            this.setState({ participant: {} });
        }
    };

    onSelectCountry = (id: number) => {
        let { mustAVS, participant } = this.state;
        const selectedCountry = this.props.countries?.find(c => c.id === id);
        if (selectedCountry?.id === 220) {
            // #TODO Temporaire
            mustAVS = true;
            // mustAVS = false
        } else {
            participant.avs = undefined;
            mustAVS = false;
        }
        participant.country = id;
        this.setState({ selectedCountry, mustAVS });
    };

    onSelectSizeJacket = (size: string) => {
        let { participant } = this.state;
        participant.sizeJacket = size;
        this.setState({ participant });
    };

    render() {
        const { formatMessage } = this.props.intl;
        // const { Text } = Typography;
        const { Option } = Select;
        // const { participant } = this.props;
        const { isEditing } = this.state;
        const GENDER: {label: string, value: number}[] = [
            { value: 0, label: formatMessage({ id: "participant.gender.male" }) },
            { value: 1, label: formatMessage({ id: "participant.gender.female" }) },
            { value: 2, label: formatMessage({ id: "participant.gender.other" }) },
        ];

        const { productSelected, detailSelected, customer, participant } = this.props;

        let price = -1;
        if (productSelected?.type === 'subscription') {
            if (customer?.domiciledAtCMAndL !== undefined && customer?.domiciledAtCMAndL) {
                if (participant.birthday && participant.birthday.isSameOrBefore(moment("30/05/2003", 'DD/MM/YYYY'), "days")) {
                    price = detailSelected ? detailSelected.price : 0;
                } else if (participant.birthday && participant.birthday.isBetween(moment("31/05/2003", 'DD/MM/YYYY'), moment("30/05/2004", 'DD/MM/YYYY'), "days", "[]")) {
                    price = detailSelected && detailSelected.priceTeenager ? detailSelected.priceTeenager : 0;
                } else {
                    price = 0;
                }
            } else if (customer?.domiciledAtI !== undefined && customer?.domiciledAtI) {
                if (participant.birthday && participant.birthday.isSameOrBefore(moment("30/05/2003", 'DD/MM/YYYY'), "days")) {
                    price = detailSelected ? detailSelected.price : 0;
                } else if (participant.birthday && participant.birthday.isBetween(moment("31/05/2003", 'DD/MM/YYYY'), moment("31/05/2004", 'DD/MM/YYYY'), "days", "[]")) {
                    price = detailSelected && detailSelected.priceTeenager ? detailSelected.priceTeenager : 0;
                } else {
                    price = 0;
                }
            } else {
                if (participant.birthday && participant.birthday.isSameOrBefore(moment("30/05/2003", 'DD/MM/YYYY'), "days")) {
                    price = detailSelected ? detailSelected.price : 0;
                } else if (participant.birthday && participant.birthday.isBetween(moment("31/05/2003", 'DD/MM/YYYY'), moment("30/05/2008", 'DD/MM/YYYY'), "days", "[]")) {
                    price = detailSelected && detailSelected.priceTeenager ? detailSelected.priceTeenager : 0;
                } else if (participant.birthday && participant.birthday.isBetween(moment("31/05/2008", 'DD/MM/YYYY'), moment("30/05/2015", 'DD/MM/YYYY'), "days", "[]")) {
                    price = detailSelected && detailSelected.priceJunior ? detailSelected.priceJunior : 0;
                } else if (participant.birthday && participant.birthday.isSameOrAfter(moment("31/05/2015", 'DD/MM/YYYY'), "days")) {
                    price = 0;
                } else {
                    price = 0;
                }
            }
        }
        return (
            isEditing ?
                <Fragment>
                    <Row gutter={[8, 8]}>
                        <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                            <Input value={this.state.participant.firstName} onChange={(e) => this.onInputChange("firstName", e.target.value)} className="form-input" placeholder={formatMessage({ id: 'participant.firstName' })} />
                        </Col>
                        <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                            <Input value={this.state.participant.lastName} onChange={(e) => this.onInputChange("lastName", e.target.value)} className="form-input" placeholder={formatMessage({ id: 'participant.lastName' })} />
                        </Col>
                        <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                            <DatePicker value={this.state.participant.birthday} onChange={(e) => this.onBirthdayChange(e)} locale={locale} className="form-input" placeholder={formatMessage({ id: 'participant.birthday' })} />
                        </Col>
                        <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                            <Select
                                value={this.state.participant.gender}
                                onChange={this.onGenderChange}
                                placeholder={formatMessage({ id: 'participant.gender' })}
                                className="form-input form-input-select"
                                filterOption={(input, option) => (option?.label as string).toLowerCase().startsWith(input.toLowerCase())}
                                options={GENDER}
                            />
                        </Col>
                        <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                            <Select
                                placeholder={formatMessage({ id: 'participant.country' })}
                                allowClear
                                showSearch
                                filterOption={(input, option) => (option?.label as string).toLowerCase().startsWith(input.toLowerCase())}
                                onChange={this.onSelectCountry}
                                className="form-input form-input-select"
                                value={this.state.participant.country}
                                options={this.props.countries?.map(c=>({label: c.translations[0].name, value: c.id}))}
                            />
                        </Col>
                        {
                            this.state.mustAVS &&
                            <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                                <Input key={`avs-${this.state.mustAVS}`} maxLength={13} disabled={!this.state.mustAVS} value={this.state.participant.avs} onChange={(e) => this.onInputChange("avs", e.target.value)} className={`form-input ${this.state.avsError ? '__avs-input-error' : ''}`} placeholder={formatMessage({ id: 'participant.avs' }) + " (sans les points : 7561234567890)"} />
                            </Col>
                        }
                        {
                            productSelected?.id === 1 &&
                            <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                                <Select
                                    placeholder={formatMessage({ id: 'participant.sizeJacket' })}
                                    showSearch
                                    filterOption={(input, option) => (option?.label as string).toLowerCase().startsWith(input.toLowerCase())}
                                    onChange={this.onSelectSizeJacket}
                                    className="form-input form-input-select"
                                    value={this.state.participant.sizeJacket}
                                    options={sizeJacket}
                                />
                            </Col>
                        }
                    </Row>
                    <Row gutter={[8, 8]} style={{ marginTop: '10px', marginBottom: '25px' }}>
                        <Col xs={24} sm={12} md={12} lg={12} xl={12} style={{ textAlign: 'left' }}>
                            {
                                this.props.participantToEditId !== undefined &&
                                <Button onClick={() => this.props.cancelParticipant()} className="form-button" type="primary" htmlType="submit">{formatMessage({ id: 'participant.cancel' })}</Button>
                            }

                        </Col>
                        <Col xs={24} sm={12} md={12} lg={12} xl={12} style={{ textAlign: 'right' }}>
                            <Button disabled={!this.state.inputsAreValidated} onClick={this.addParticipant} className="form-button" type="primary" htmlType="submit">{this.props.participantToEditId === undefined ? formatMessage({ id: 'participant.save' }) : formatMessage({ id: 'participant.edit' })}</Button>
                        </Col>
                    </Row>
                </Fragment>
                :
                <Col xs={24} sm={12} md={8} lg={8} xl={6}>
                    <Card title={`${participant.firstName} ${participant.lastName}  ${price > -1 ? "(FR. " + price + ".-)" : ''}`} bordered={false}
                        style={this.props.participantToEditId !== undefined ? this.props.participantToEditId !== this.props.id ? { opacity: "0.5" } : { cursor: 'progress' } : {}}
                        extra={
                            <Space direction='horizontal'>
                                <EditOutlined style={this.props.participantToEditId !== undefined ? { cursor: 'not-allowed' } : {}} key={`participant-edit-${this.props.id}`} onClick={() => this.props.participantToEditId === undefined && this.props.editParticipants(this.props.id, participant)} />
                                <DeleteOutlined style={this.props.participantToEditId !== undefined ? { cursor: 'not-allowed' } : {}} key={`participant-delete-${this.props.id}`} onClick={() => this.props.participantToEditId === undefined && this.props.deleteParticipant(this.props.id)} />
                            </Space>
                        }>
                        <p><span style={{ fontWeight: 'bold' }}>{formatMessage({ id: 'participant.birthday' })} : </span>{moment(participant.birthday).format('DD/MM/YYYY')}</p>
                        <p><span style={{ fontWeight: 'bold' }}>{formatMessage({ id: 'participant.gender' })} : </span>{participant && participant.gender != null && GENDER.find(gender => gender.value === participant.gender)?.label}</p>
                        {
                            participant && participant.avs &&
                            <p><span style={{ fontWeight: 'bold' }}>{formatMessage({ id: 'participant.avs' })} : </span>{participant.avs}</p>
                        }
                        {
                            participant && participant.sizeJacket &&
                            <p><span style={{ fontWeight: 'bold' }}>{formatMessage({ id: 'participant.sizeJacket' })} : </span>{participant.sizeJacket}</p>
                        }
                    </Card>
                </Col>
        );
    }
}

export default injectIntl(ParticipantItem);