import axiosapi from './api';
import { PayyoCaptureBobyRequest, RegistrationBobyRequest } from './types';

// const headers = {
//     'Content-Type': 'application/json',
//     'Accept': 'application/json',
//     'Authorization': 'Basic YXBpXzdkOTE4OTg5Y2MzYmM0MjcyOWZjNzc2NGIxNDU6NmU2N2NhY2FhNWNlODQ3Nzg1NTJkNTZjMmFkMWMxNWRjMGI5OWJlZGZmYmU2OTkwNGMwNDYzMmFiYzk4ZDYyNg=='
// }

export default class Network {
    static getCountriesList = (lang: string): Promise<any> => axiosapi.get(`statics/countries?lang=${lang}`);
    // static getCantonList = (lang: string): Promise<any> => axiosapi.get(`statics/cantons?lang=${lang}`);

    static register = (info: RegistrationBobyRequest): Promise<any> => axiosapi.post('payment/payyo', info);
    static payyoCapture = (info: PayyoCaptureBobyRequest): Promise<any> => axiosapi.post('payment/payyo', info);

    // static createCustomer = (info: CustomerBodyRequest): Promise<any> => axiosapi.post('payment/payyo', info);
    static getMail = (query:string, lang: string, customerId: number): Promise<any> => axiosapi.get(`payment/payyo`, { params: { "query": query, "lang": lang, "customerId" : customerId }});
    static sendIbanToCustomer = (query: string, lang: string, customerId: number): Promise<any> => axiosapi.get(`payment/payyo`, { params: { "query": query,  "lang": lang, "customerId" : customerId }});
}